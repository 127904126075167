<template>
	<div>
    <Toast />
    <br>
    <div class="md:w-1/4 px-3" v-if="activeDetalle">
      <label class="uppercase tracking-wide  text-xs font-bold mb-2" >Selecionar detalle</label>
      <Dropdown class="w-full bg-gray-200 border border-gray-200  text-xs py-3 px-4 pr-8 mb-3 rounded" @change="setDatalle" v-model="valueDetalle" :filter="true" :options="optionsDetalle" optionLabel="name" placeholder="Select" />
    </div>
    <FormSyscom :order="order" :loading="loading" :button="true" :method="method" @save-order="saveOrder" @save-syscom="saveSyscom" />
	</div>
</template>
<script>
  import AcceptedOrder from '../service/AcceptedOrder';
  import {API_LOCAL_URL, API_SYSCOM_URL} from "@/config";
  import axios from "axios";
  import FormSyscom from "@/components/forms/FormSyscom";

	export default {
    components: {
      FormSyscom
    },
		data() {
			return {
        solicitudServicio: null,
        loading: true,
        method: 'post',
        paramId: null,
        orderCopy: {},
        tipoServicio: null,
        services: [],
        solicitudGeneral: {},
        servicePoint: 0,
        fechas: {
          "FecDespacho":null,
          "FecEntrega":null,
          "FecVencePol":null,
          "Fecha":null
        },
        detalleItem: {
          "Cajas":null,
          "Cantidad":null,
          "Cases":null,
          "CdRango":null,
          "CiudadDestino":null,
          "CiudadOrigen":null,
          "Contenedor1":null,
          "Contenedor2":null,
          "DescripMcias":null,
          "DirDestino":null,
          "DirOrigen":null,
          "DocCliente":null,
          "Embalajes":null,
          "IdEmpaque":null,
          "IdManejo":null,
          "IdMercancia":null,
          "IdNaturaleza":null,
          "NitDestinatario":null,
          "NitRemitente":null,
          "Palets":null,
          "Peso":null,
          "Referencia1":null,
          "Referencia2":null,
          "Referencia3":null,
          "Riesgos": "NORMAL",
          "SedeDestinatario":null,
          "SedeRemitente":null,
          "TarifSeguro": "0",
          "TarifaCliente":null,
          "TarifaPago":null,
          "TarifaTabla":null,
          "Tipo_Servicio": "EXPRESO",
          "UndMed": {
            "UndMed": "kg",
            "Unidad": "kilogramo"
          },
          "UndTarifaPago": "UNIDADES",
          "UndVol": {
            code: "m3",
            name: "METRO CÚBICO"
          },
          "UnidadCliente": "UNIDADES",
          "Volumen":0,
          "VrDeclarado":null,
          "dmsAlto":0,
          "dmsAncho":0,
          "dmsLargo":0,
          "solicitud":null,
          "tipoEntrega": "otros",
          "idEntrega": null,
          "solicitudServicio": null
        },
        conceptoItem:{
          'Cantidad':null,
          'CodigoConcepto':null,
          'Descripcion':null,
          'Nit':null,
          'Rubro':null,
          'TipoConcepto':null,
          'TipoEscolta':null,
          'VrUnitario':null,
          'solicitud':null
        },
        order: {
          Concepto: [],
				  Detalle: [],
          Encabezado: {
            "Cargue": "EMPRESA",
            "CdTipCarga": {
              IdTipCarga: "0",
              TipoCarga: "PREDETERMINADO"
            },
            "CdTipoEsc":null,
            "CdTipoVehic":null,
            "CiudadDevContenedor": {
              IdLocal: "11001",
              Localidad: "BOGOTA D.C."
            },
            "ContactoSIA":null,
            "Descargue": "EMPRESA",
            "DevContenedor":false,
            "DiasVigencia":null,
            "Embarque": "SUELTA",
            "FecDespacho":null,
            "FecEntrega":null,
            "FecVencePol":null,
            "Fecha":null,
            "IdAgencia":null,
            "IdRuta": "0000",
            "IdVend":null,
            "MargenFalt": 0,
            "Modalidad": "ESTANDAR",
            "Moneda": {
              idMneda: "COP",
              Mneda: "Peso colombiano"
            },
            "NitCiaPoliza":null,
            "NitCliente":null,
            "nombre_cliente":null,
            "NitSIA":null,
            "NomContacto":null,
            "NomContactoDest":null,
            "NumPolizaEsp":null,
            "Observacion":null,
            "PatioCont":null,
            "PolizaEspecifica":false,
            "Seguros": "EMPRESA",
            "TarifFaltCobro":null,
            "TarifFaltPago":null,
            "TarifaComision":null,
            "TelContacto":null,
            "TelContactoDest":null,
            "TeleContactoSIA":null,
            "TipoMargen": "UNIDAD",
            "TipoRuta": "NACIONAL",
            "TipoTarifa": "PEDIDO",
            "TipoTransporte":null,
            "UndCalcFalt": "PESO",
            "Vigencia": "NORMAL",
            "VrCargos":0,
            "VrCargue":0,
            "VrDctos":0,
            "VrDesCargue":0,
            "VrDevolucionContdor":0,
            "VrEmbalajes":0,
            "VrEscolta":0,
            "VrLimiteDesp":null,
            "VrTasa":null,
            "VrTraUrbano":0,
            "emailContac":null,
            "emailContacDest":null,
            NomCliente: null,
            FacturarA: null,
            NomRemitente: null,
            NomDestinatario: null,
            //Search
            IdClie: null,
            IdTercero: null,
            IdTercero1: null,
            IdTercero2: null,
            NomCiaPoliza: null,
            IdTercero3: null,
            NomNit: null,
            CodRuta: null,
            NomRuta: null
          }
        },
        optionsTipoEscolta: [
          {
            code: "0003",
            name: "EN CABINA"
          },
          {
            code: "0002",
            name: "MOTO"
          },
          {
            code: "0001",
            name: "VEHICULAR"
          }
        ],
        optionsUndVol: [
          {
            code: "m3",
            name: "METRO CÚBICO"
          }
        ],
        optionDetalle: {
          value: null,
          name: null
        },
        activeDetalle: false,
        valueDetalle: null,
        optionsDetalle: [
        ]
			}
		},
    AcceptedOrder: null,
    watch: {
      $route(value) {
        switch (value.name) {
          case 'generateorder':
          case 'generateorder2':
          case 'pathorder':
          case 'duplicateorder':
            Object.assign(this.$data, this.$options.data());
            this.methodMounted();
            break;
        }
      }
    },
    created() {
      this.AcceptedOrder = new AcceptedOrder();
    },
    mounted() {
		  this.methodMounted();
    },
    methods: {
      methodMounted () {
        this.loading = true;
        if(this.$route.params.id) { //Panalpina.
          this.activeDetalle = true;
          this.tipoServicio = 'Bdp';
          this.AcceptedOrder.getOrder(this.$route.params.id).then(data => { //Traer datos de panalpina.
            for (const property in data) { //Guardar datos de todas las propiedades de la solicitud sin incluir información de entrega.
              if (property != 'info_entregas') {
                this.solicitudGeneral[property] = data[property];
              }
              if (property == 'tipoSolicitud') {
                if (this.solicitudGeneral[property] == 'DSV') {
                  this.tipoServicio = 'Panalpina';
                }
              }
            }

            for (const j in data.info_entregas) {// Separar y Guardar cada información de entrega, para hacer envios independientes
              for (const i in data.info_entregas[j].mercancia) {// Generar solicitudes por mercancias.
                this.services.push(JSON.parse(JSON.stringify(this.solicitudGeneral)));
                this.services[this.services.length - 1]['info_entregas'] = [];
                this.services[this.services.length - 1].info_entregas.push({});
                for (const property in data.info_entregas[j]) { // Guardar información de entregas.
                  if (property != 'mercancia' && property != 'contenedor') {
                    this.services[this.services.length - 1].info_entregas[0][property] = data.info_entregas[j][property];
                  }
                }
                this.services[this.services.length - 1].info_entregas[0]['mercancia'] = [];
                this.services[this.services.length - 1].info_entregas[0].mercancia.push(JSON.parse(JSON.stringify(data.info_entregas[j].mercancia[i])));

                let selectActive = true;
                for (const x in data.info_pedidos) {
                  if (data.info_pedidos[x].tipoEntrega === "mercancia" && data.info_entregas[j].mercancia[i].id === data.info_pedidos[x].idEntrega) {
                    selectActive = false;
                  }
                }
                if (selectActive) {
                  this.optionsDetalle.push(JSON.parse(JSON.stringify(this.optionDetalle)));
                  this.optionsDetalle[this.optionsDetalle.length - 1].value = this.services.length - 1;
                  this.optionsDetalle[this.optionsDetalle.length - 1].name = 'Nº de orden ' + data.info_entregas[j].mercancia[i].documentoOrden;
                }
              }
              for (const i in data.info_entregas[j].contenedor) { // Generar solicitudes por contenedores.
                this.services.push(JSON.parse(JSON.stringify(this.solicitudGeneral)));
                this.services[this.services.length - 1]['info_entregas'] = [];
                this.services[this.services.length - 1].info_entregas.push({});
                for (const property in data.info_entregas[j]) {
                  if (property != 'mercancia' && property != 'contenedor') {
                    this.services[this.services.length - 1].info_entregas[0][property] = data.info_entregas[j][property];
                  }
                }
                this.services[this.services.length - 1].info_entregas[0]['contenedor'] = [];
                this.services[this.services.length - 1].info_entregas[0].contenedor.push(JSON.parse(JSON.stringify(data.info_entregas[j].contenedor[i])));

                let selectActive = true;
                for (const x in data.info_pedidos) {
                  if (data.info_pedidos[x].tipoEntrega === "contenedor" && data.info_entregas[j].contenedor[i].id === data.info_pedidos[x].idEntrega) {
                    selectActive = false;
                  }
                }
                if (selectActive) {
                  this.optionsDetalle.push(JSON.parse(JSON.stringify(this.optionDetalle)));
                  this.optionsDetalle[this.optionsDetalle.length - 1].value = this.services.length - 1;
                  this.optionsDetalle[this.optionsDetalle.length - 1].name = 'Nº de contenedor ' + data.info_entregas[j].contenedor[i].contenedorNumero;
                }
              }
            }
            this.setData(this.services[this.optionsDetalle[0].value]); // Mostrar primer solicitud, haciendo equivalente
            this.loading = false;
          })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error al traer los datos',
                  detail: 'Error al traer los datos',
                  life: 5000
                });
                this.loading = false;
              });
        } else if (this.$route.params.edit || this.$route.params.oldid) {

          this.paramId = this.$route.params.oldid;
          if (this.$route.params.edit) {
            this.method = 'put';
            this.paramId = this.$route.params.edit;
          }

          this.AcceptedOrder.getServiceOrder(this.paramId).then(data => {

            this.order.Encabezado = JSON.parse(JSON.stringify(data));
            this.order.Detalle = JSON.parse(JSON.stringify(data.detalle));
            this.order.Concepto = JSON.parse(JSON.stringify(data.concepto));

            this.tipoServicio = this.order.Encabezado.tipo_servicio;
            if (this.method == 'put') {
              this.solicitudServicio = this.order.Encabezado.solicitud.solicitudServicio;
            } else {
              this.order.Encabezado.id = null;
            }
            this.order.Encabezado.IdClie = this.order.Encabezado.NitCliente.IdClie;
            this.order.Encabezado.NomCliente = this.order.Encabezado.NitCliente.NomRepLeg2;
            this.order.Encabezado.nombre_cliente = this.order.Encabezado.NitCliente.NomRepLeg2;
            this.order.Encabezado.FacturarA = this.order.Encabezado.NitCliente.RazonSocialFact;
            if (this.order.Encabezado.NitCliente.RazonSocialFact !== null) {
              this.order.Encabezado.FacturarA = [this.order.Encabezado.NitCliente.RazonSocialFact.IdTercero, this.order.Encabezado.NitCliente.RazonSocialFact.RazonSocial].join(' ');
            }
            if (this.order.Encabezado.NitCiaPoliza !== null) {
              this.order.Encabezado.IdTercero2 = this.order.Encabezado.NitCiaPoliza.IdTercero;
              this.order.Encabezado.NomCiaPoliza = this.order.Encabezado.NitCiaPoliza.NomRepLeg2;
            }
            this.order.Encabezado.CodRuta = this.order.Encabezado.IdRuta.IdRuta;
            this.order.Encabezado.NomRuta = this.order.Encabezado.IdRuta.Ruta;

            for (const i in this.order.Detalle) {
              this.order.Encabezado.IdTercero = this.order.Detalle[i].NitRemitente.IdTercero;
              this.order.Encabezado.NomRemitente = this.order.Detalle[i].NitRemitente.RazonSocial2;
              this.order.Encabezado.IdTercero1 = this.order.Detalle[i].NitDestinatario.IdTercero;
              this.order.Encabezado.NomDestinatario = this.order.Detalle[i].NitDestinatario.RazonSocial2;
              if (this.method != 'put') {
                this.order.Detalle[i].id = null;
                this.order.Detalle[i].tipoEntrega = "otros";
              }
              for (const j in this.optionsUndVol) {
                if (this.optionsUndVol[j].code == this.order.Detalle[i].UndVol) {
                  this.order.Detalle[i].UndVol = this.optionsUndVol[j];
                }
              }
            }

            for (const i in this.order.Concepto) {
              if (this.method != 'put') {
                this.order.Concepto[i].id = null;
              }
              if (this.order.Concepto[i].Nit !== null) {
                this.order.Encabezado.IdTercero3 = this.order.Concepto[i].Nit.IdTercero;
                this.order.Encabezado.NomNit = this.order.Concepto[i].Nit.RazonSocial2;
              }
              for (const j in this.optionsTipoEscolta) {
                if (this.optionsTipoEscolta[j].code == this.order.Concepto[i].TipoEscolta) {
                  this.order.Concepto[i].TipoEscolta = this.optionsTipoEscolta[j];
                }
              }
            }

            this.order.Encabezado.PolizaEspecifica =  (this.order.Encabezado.PolizaEspecifica == 1) ? true : false;
            this.order.Encabezado.DevContenedor =  (this.order.Encabezado.DevContenedor == 1) ? true : false;
            if (this.order.Encabezado.FecDespacho !== null) {
              this.order.Encabezado.FecDespacho = this.order.Encabezado.FecDespacho.replace(" ", "T");
            }
            if (this.order.Encabezado.FecEntrega !== null) {
              this.order.Encabezado.FecEntrega = this.order.Encabezado.FecEntrega.replace(" ", "T");
            }
            if (this.order.Encabezado.FecVencePol !== null) {
              this.order.Encabezado.FecVencePol = this.order.Encabezado.FecVencePol.replace(" ", "T");
            }
            if (this.order.Encabezado.Fecha !== null) {
              this.order.Encabezado.Fecha = this.order.Encabezado.Fecha.replace(" ", "T");
            }


            this.loading = false;

          });

        }else{
          this.loading = false;
          this.tipoServicio = 'Otros';
          this.order.Detalle.push(JSON.parse(JSON.stringify(this.detalleItem)));
          this.order.Concepto.push(JSON.parse(JSON.stringify(this.conceptoItem)));
        }
      },
		  clearDetalle(){
        Object.assign(this.$data.detalleItem, this.$options.data().detalleItem);
      },
      setData(dataOrder){
        this.solicitudServicio = dataOrder.solicitudServicio; // Guardar numero de solicitud de servicio.
        //Cargar Encabezado
        this.order.Encabezado.NomContacto = dataOrder.remitenteContacto;
        if (dataOrder.remitenteContacto == null ||
            (dataOrder.remitenteContacto && dataOrder.remitenteContacto.trim()) === "" ||
            dataOrder.remitenteContacto === 0 ||
            typeof dataOrder.remitenteContacto === 'undefined' ||
            dataOrder.remitenteContacto === "0") {
          this.order.Encabezado.NomContacto = "Janett Barreto";
        }
        this.order.Encabezado.TelContacto = dataOrder.remitenteTelefono;
        this.order.Encabezado.FecDespacho = dataOrder.fechaCargue + 'T' + dataOrder.horaCargue;
        //this.order.Encabezado.Observacion = dataOrder.observacion;
        this.order.Encabezado.NitCliente = dataOrder.clienteDocumento;
        this.order.Encabezado.NomContactoDest = dataOrder.info_entregas[0].destinatarioContacto;
        if (dataOrder.info_entregas[0].destinatarioContacto == null ||
            (dataOrder.destinatarioContacto && dataOrder.destinatarioContacto.trim()) === "" ||
            dataOrder.info_entregas[0].destinatarioContacto === 0 ||
            typeof dataOrder.info_entregas[0].destinatarioContacto === 'undefined' ||
            dataOrder.info_entregas[0].destinatarioContacto === "0") {
          this.order.Encabezado.NomContactoDest = "Janett Barreto";
        }
        this.order.Encabezado.TelContactoDest = dataOrder.info_entregas[0].destinatarioTelefono;
        this.order.Encabezado.FecEntrega = dataOrder.info_entregas[0].entregaEstimadaFecha + 'T' + dataOrder.info_entregas[0].entregaEstimadaHora;

        //Cargar detalles
        this.setPost(dataOrder);

        //Cargar Conceptos
        for (const i in dataOrder.info_entregas[0].servicioAdicional) {
          Object.assign(this.$data.conceptoItem, this.$options.data().conceptoItem);
          this.conceptoItem.Rubro = dataOrder.info_entregas[0].servicioAdicional[i].servicioAdicional.nombre;
          this.conceptoItem.VrUnitario = dataOrder.info_entregas[0].servicioAdicional[i].valor;
          this.conceptoItem.Cantidad = dataOrder.info_entregas[0].servicioAdicional[i].cantidad;
          this.conceptoItem.Descripcion = dataOrder.info_entregas[0].servicioAdicional[i].observacionEmpresa;
          this.order.Concepto.push(JSON.parse(JSON.stringify(this.conceptoItem)));
        }
      },
      setPost(dataOrder){
        //Cargar detalles
        for (const i in dataOrder.info_entregas[0].mercancia) {
          this.clearDetalle();
          this.detalleItem.Tipo_Servicio = dataOrder.tipoServicio;
          //this.detalleItem.CiudadOrigen = dataOrder.ciudadOrigenCodigoDane;
          //this.detalleItem.CiudadDestino = dataOrder.ciudadDestinoCodigoDane;
          this.detalleItem.DirOrigen = dataOrder.remitenteDireccion;
          this.detalleItem.DirDestino = dataOrder.info_entregas[0].destinatarioDireccion;
          //this.detalleItem.IdMercancia = dataOrder.info_entregas[0].mercancia[i].itemMercancia;
          //this.detalleItem.Embalajes = dataOrder.info_entregas[0].mercancia[i].empaqueNombre;
          this.detalleItem.Cantidad = dataOrder.info_entregas[0].mercancia[i].cantidad;
          if (this.detalleItem.Cantidad === null || this.detalleItem.Cantidad === "" || this.detalleItem.Cantidad < 1) {
            this.detalleItem.Cantidad = 1;
          }
          this.detalleItem.VrDeclarado = dataOrder.info_entregas[0].mercancia[i].valorMercancia;
          this.detalleItem.dmsAlto = dataOrder.info_entregas[0].mercancia[i].alto;
          if (this.detalleItem.dmsAlto === null || this.detalleItem.dmsAlto === "") {
            this.detalleItem.dmsAlto = 0;
          }
          this.detalleItem.dmsLargo = dataOrder.info_entregas[0].mercancia[i].largo;
          if (this.detalleItem.dmsLargo === null || this.detalleItem.dmsLargo === "") {
            this.detalleItem.dmsLargo = 0;
          }
          this.detalleItem.dmsAncho = dataOrder.info_entregas[0].mercancia[i].ancho;
          if (this.detalleItem.dmsAncho === null || this.detalleItem.dmsAncho === "") {
            this.detalleItem.dmsAncho = 0;
          }
          //this.detalleItem.Riesgos = dataOrder.info_entregas[0].mercancia[i].naturalezaCarga;
          this.detalleItem.DescripMcias = dataOrder.info_entregas[0].mercancia[i].descripcionMercancia;
          this.detalleItem.Peso = dataOrder.info_entregas[0].mercancia[i].peso;
          //this.detalleItem.UndMed = dataOrder.info_entregas[0].mercancia[i].pesoUnidadMedida;
          this.detalleItem.Volumen = dataOrder.info_entregas[0].mercancia[i].volumen;
          if (this.detalleItem.Volumen === null || this.detalleItem.Volumen === "") {
            this.detalleItem.Volumen = 0;
          }
          //this.detalleItem.UndVol = dataOrder.info_entregas[0].mercancia[i].volumenUnidadMedida;
          this.detalleItem.tipoEntrega = "mercancia";
          this.detalleItem.idEntrega = dataOrder.info_entregas[0].mercancia[i].id;
          this.detalleItem.solicitudServicio = this.solicitudServicio;
          this.order.Detalle.push(JSON.parse(JSON.stringify(this.detalleItem)));
        }

        for (const i in dataOrder.info_entregas[0].contenedor) {
          this.clearDetalle();
          this.detalleItem.Tipo_Servicio = dataOrder.tipoServicio;
          //this.detalleItem.CiudadOrigen = dataOrder.ciudadOrigenCodigoDane;
          //this.detalleItem.CiudadDestino = dataOrder.ciudadDestinoCodigoDane;
          this.detalleItem.DirOrigen = dataOrder.remitenteDireccion;
          this.detalleItem.DirDestino = dataOrder.info_entregas[0].destinatarioDireccion;
          //this.detalleItem.IdMercancia = dataOrder.info_entregas[0].contenedor[i].itemContenedor;
          this.detalleItem.Contenedor1 = dataOrder.info_entregas[0].contenedor[i].contenedorNumero;
          this.detalleItem.Contenedor2 = dataOrder.info_entregas[0].contenedor[i].contenedorNumero;
          this.detalleItem.VrDeclarado = dataOrder.info_entregas[0].contenedor[i].valorMercancia;
          //this.detalleItem.Riesgos = dataOrder.info_entregas[0].contenedor[i].naturalezaCarga;
          this.detalleItem.DescripMcias = dataOrder.info_entregas[0].contenedor[i].descripcionMercancia;
          this.detalleItem.Peso = dataOrder.info_entregas[0].contenedor[i].peso;
          this.detalleItem.Embalajes = {
            "IdUnd": "0302",
            "Unidad": "Contenedor"
          };
          //this.detalleItem.UndMed = dataOrder.info_entregas[0].contenedor[i].pesoUnidadMedida;
          this.detalleItem.tipoEntrega = "contenedor";
          this.detalleItem.idEntrega = dataOrder.info_entregas[0].contenedor[i].id;
          this.detalleItem.solicitudServicio = this.solicitudServicio;
          this.order.Detalle.push(JSON.parse(JSON.stringify(this.detalleItem)));
        }

      },
      saveData(){ // Mitigar errores de selects y hacer copias de algunos datos

		    //Realizar copia del pedido actual
        this.orderCopy = JSON.parse(JSON.stringify(this.order));

        //Selects Encabezado
        if (this.order.Encabezado.CdTipCarga !== null) {
          this.order.Encabezado.CdTipCarga = this.order.Encabezado.CdTipCarga.IdTipCarga;
        }
        if (this.order.Encabezado.CdTipoEsc !== null) {
          this.order.Encabezado.CdTipoEsc = this.order.Encabezado.CdTipoEsc.IdTipoEsc;
        }
        if (this.order.Encabezado.CdTipoVehic !== null) {
          this.order.Encabezado.CdTipoVehic = this.order.Encabezado.CdTipoVehic.IdTipoVeh;
        }
        if (this.order.Encabezado.Moneda !== null) {
          this.order.Encabezado.Moneda = this.order.Encabezado.Moneda.idMneda;
        }
        if (this.order.Encabezado.IdVend !== null) {
          this.order.Encabezado.IdVend = this.order.Encabezado.IdVend.idVend;
        }
        if (this.order.Encabezado.IdRuta !== null) {
          this.order.Encabezado.IdRuta = this.order.Encabezado.IdRuta.IdRuta;
        }
        if (this.order.Encabezado.NitCliente !== null) {
          this.order.Encabezado.NitCliente = this.order.Encabezado.NitCliente.IdClie;
        }
        if (this.order.Encabezado.NitCiaPoliza !== null) {
          this.order.Encabezado.NitCiaPoliza = this.order.Encabezado.NitCiaPoliza.IdTercero;
        }
        if (this.order.Encabezado.IdAgencia !== null) {
          this.order.Encabezado.IdAgencia = this.order.Encabezado.IdAgencia.idAgencia;
        }
        if (this.order.Encabezado.TarifaComision !== null) {
          this.order.Encabezado.TarifaComision = this.order.Encabezado.TarifaComision.IdTarifa;
        }
        if (this.order.Encabezado.CiudadDevContenedor !== null) {
          this.order.Encabezado.CiudadDevContenedor = this.order.Encabezado.CiudadDevContenedor.IdLocal;
        }

        //Selects Detalle
        for (const i in this.order.Detalle) {
          if (this.order.Detalle[i].UndVol !== null) {
            this.order.Detalle[i].UndVol = this.order.Detalle[i].UndVol.code;
          }
          if (this.order.Detalle[i].CdRango !== null) {
            this.order.Detalle[i].CdRango = this.order.Detalle[i].CdRango.IdRango;
          }
          if (this.order.Detalle[i].Embalajes !== null) {
            this.order.Detalle[i].Embalajes = this.order.Detalle[i].Embalajes.IdUnd;
          }
          if (this.order.Detalle[i].IdEmpaque !== null) {
            this.order.Detalle[i].IdEmpaque = this.order.Detalle[i].IdEmpaque.IdEmpaque;
          }
          if (this.order.Detalle[i].IdManejo !== null) {
            this.order.Detalle[i].IdManejo = this.order.Detalle[i].IdManejo.IdManejo;
          }
          if (this.order.Detalle[i].IdMercancia !== null) {
            this.order.Detalle[i].IdMercancia = this.order.Detalle[i].IdMercancia.IdMercancia;
          }
          if (this.order.Detalle[i].IdNaturaleza !== null) {
            this.order.Detalle[i].IdNaturaleza = this.order.Detalle[i].IdNaturaleza.IdNat;
          }
          if (this.order.Detalle[i].UndMed !== null) {
            this.order.Detalle[i].UndMed = this.order.Detalle[i].UndMed.UndMed;
          }
          if (this.order.Detalle[i].CiudadDestino !== null) {
            this.order.Detalle[i].CiudadDestino = this.order.Detalle[i].CiudadDestino.IdLocal;
          }
          if (this.order.Detalle[i].CiudadOrigen !== null) {
            this.order.Detalle[i].CiudadOrigen = this.order.Detalle[i].CiudadOrigen.IdLocal;
          }
          if (this.order.Detalle[i].NitDestinatario !== null) {
            this.order.Detalle[i].NitDestinatario = this.order.Detalle[i].NitDestinatario.IdTercero;
          }
          if (this.order.Detalle[i].NitRemitente !== null) {
            this.order.Detalle[i].NitRemitente = this.order.Detalle[i].NitRemitente.IdTercero;
          }
          if (this.order.Detalle[i].SedeDestinatario !== null) {
            this.order.Detalle[i].SedeDestinatario = this.order.Detalle[i].SedeDestinatario.IdSede;
          }
          if (this.order.Detalle[i].SedeRemitente !== null) {
            this.order.Detalle[i].SedeRemitente = this.order.Detalle[i].SedeRemitente.IdSede;
          }
        }

        //Selects Concepto
        for (const i in this.order.Concepto) {
          if (this.order.Concepto[i].CodigoConcepto !== null) {
            this.order.Concepto[i].CodigoConcepto = this.order.Concepto[i].CodigoConcepto.idConcepto;
          }
          if (this.order.Concepto[i].Nit !== null) {
            this.order.Concepto[i].Nit = this.order.Concepto[i].Nit.IdTercero;
          }
          if (this.order.Concepto[i].TipoEscolta !== null) {
            this.order.Concepto[i].TipoEscolta = this.order.Concepto[i].TipoEscolta.code;
          }
        }

        //Guardar copia de las fechas
        this.fechas.FecDespacho = this.order.Encabezado.FecDespacho;
        this.fechas.FecEntrega = this.order.Encabezado.FecEntrega
        this.fechas.FecVencePol = this.order.Encabezado.FecVencePol;
        this.fechas.Fecha = this.order.Encabezado.Fecha;
        this.fechas = JSON.parse(JSON.stringify(this.fechas));

      },
      async setOrderSyscom(){
        this.loading = true;
        var axios1, axios2, axios3;
        this.saveData();

        const config = {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        };

        axios1 = await new Promise((resolve)=>{
          const formData = {
            "Usuario": {
              "Compania": "01",
              "Nit": "900377283",
              "Password": "NMV900377",
              "Usuario": "900377283"
            }
          };
          axios
              .post(API_SYSCOM_URL+'GenerarToken', formData, config)
              .then(response => {
                if (response.data.Errores === null) {
                  resolve(response.data);
                }else{
                  this.loading = false;
                  this.order = JSON.parse(JSON.stringify(this.orderCopy));
                  this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:response.data.Errores.descripcion, life: 5000});
                }
              })
              .catch(() => {
                this.loading = false;
                this.order = JSON.parse(JSON.stringify(this.orderCopy));
                this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'Error de solicitud', life: 5000});
              });
        });

        let integerEncabezado = [
          'DiasVigencia',
          'MargenFalt',
          'TarifFaltCobro',
          'TarifFaltPago',
          'TarifaComision',
          'VrCargos',
          'VrCargue',
          'VrDctos',
          'VrDesCargue',
          'VrDevolucionContdor',
          'VrEmbalajes',
          'VrEscolta',
          'VrLimiteDesp',
          'VrTasa',
          'VrTraUrbano',
        ];

        let hideEncabezado = [
          'detalle',
          'concepto',
          'info_syscomResponse',
          'info_trazabilidad',
          'novedad',
          'vehiculo',
          'FecDespacho',
          'FecEntrega',
          'FecVencePol',
          'Fecha',
        ];

        for (const property in this.order.Encabezado) {
          if (!hideEncabezado.includes(property)) {
            if (this.order.Encabezado[property] == "" || this.order.Encabezado[property] === null) {
              if (property == 'DevContenedor' || property == 'PolizaEspecifica') {
                this.order.Encabezado[property] = false;
              } else {
                if (integerEncabezado.includes(property)) {
                  this.order.Encabezado[property] = 0;
                } else {
                  //this.order.Encabezado[property] = "void";
                }
              }
            }
          }
        }

        let integerDetalle = [
          'Cajas',
          'Cantidad',
          'Cases',
          'DocCliente',
          'NitDestinatario',
          'NitRemitente',
          'Palets',
          'Peso',
          'TarifSeguro',
          'TarifaCliente',
          'TarifaPago',
          'TarifaTabla',
          'Volumen',
          'VrDeclarado',
          'dmsAlto',
          'dmsAncho',
          'dmsLargo',
        ];

        for (const i in this.order.Detalle) {
          this.order.Detalle[i].TarifaTabla = document.getElementById("TarifaTabla").value;
          this.order.Detalle[i].TarifaPago = document.getElementById("TarifaPago").value;
          this.order.Detalle[i].TarifSeguro = document.getElementById("TarifSeguro").value;
          this.order.Detalle[i].TarifaCliente = document.getElementById("TarifaCliente").value;
          for (const property in this.order.Detalle[i]) {
            if (this.order.Detalle[i][property] === "" || this.order.Detalle[i][property] === null) {
              if (integerDetalle.includes(property)) {
                this.order.Detalle[i][property] = 0;
              } else {
                //this.order.Detalle[i][property] = "void";
              }
            } else {
              if (property === "TarifaTabla" ||
                  property === "TarifaPago" ||
                  property === "TarifSeguro" ||
                  property === "TarifaCliente") {
                if (this.order.Detalle[i][property].charAt(this.order.Detalle[i][property].length - 3) === '.') {
                  this.order.Detalle[i][property] = this.order.Detalle[i][property].slice(0, -3);
                }
                this.order.Detalle[i][property] = this.order.Detalle[i][property].replace(",", "");
                this.order.Detalle[i][property] = this.order.Detalle[i][property].replace(",", "");
                this.order.Detalle[i][property] = this.order.Detalle[i][property].replace(",", "");
                this.order.Detalle[i][property] = this.order.Detalle[i][property].replace(",", "");
                this.order.Detalle[i][property] = this.order.Detalle[i][property].replace(",", "");
              }
            }
          }
        }

        let integerConcepto = [
          'Cantidad',
          'VrUnitario'
        ];

        for (const i in this.order.Concepto) {
          let contConcepto = 0;
          for (const property in this.order.Concepto[i]) {
            if (this.order.Concepto[i][property] == "" || this.order.Concepto[i][property] === null) {
              contConcepto++;
              if (integerConcepto.includes(property)) {
                this.order.Concepto[i][property] = 0;
              } else {
                //this.order.Concepto[i][property] = "void";
              }
            }
          }
          if (contConcepto == Object.keys(this.order.Concepto[i]).length) {
            this.order.Concepto = JSON.parse(JSON.stringify(this.order.Concepto.splice(i,1)));
          }
        }

        //Formato Fecha syscom
        if (this.order.Encabezado.FecDespacho === "" || this.order.Encabezado.FecDespacho === null) {
          this.loading = false;
          this.order = JSON.parse(JSON.stringify(this.orderCopy));
          this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'El campo fecha del despacho es obligatorio.', life: 5000});
          return false;
        } else {
          let FecDespacho = new Date(this.order.Encabezado.FecDespacho);
          this.order.Encabezado.FecDespacho = '/Date('+FecDespacho.getTime()+'-0500)/';
        }

        if (this.order.Encabezado.FecEntrega === "" || this.order.Encabezado.FecEntrega === null) {
          this.loading = false;
          this.order = JSON.parse(JSON.stringify(this.orderCopy));
          this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'El campo fecha programada de entrega es obligatorio.', life: 5000});
          return false;
        } else {
          let FecEntrega = new Date(this.order.Encabezado.FecEntrega);
          this.order.Encabezado.FecEntrega = '/Date('+FecEntrega.getTime()+'-0500)/';
        }

        if (this.order.Encabezado.FecVencePol !== "" && this.order.Encabezado.FecVencePol !== null) {
          let FecVencePol = new Date(this.order.Encabezado.FecVencePol);
          this.order.Encabezado.FecVencePol = '/Date('+FecVencePol.getTime()+'-0500)/';
        }

        if (this.order.Encabezado.Fecha === "" || this.order.Encabezado.Fecha === null) {
          let Fecha = new Date();
          this.order.Encabezado.Fecha = '/Date('+Fecha.getTime()+'-0500)/';
        } else {
          let Fecha = new Date(this.order.Encabezado.Fecha);
          this.order.Encabezado.Fecha = '/Date('+Fecha.getTime()+'-0500)/';
        }

        if (this.order.Encabezado.Observacion !== "" && this.order.Encabezado.Observacion !== null) {
          this.order.Encabezado.Observacion = this.order.Encabezado.Observacion.substring(0, 240);
        }

        axios2 = await new Promise((resolve)=>{
          this.order['Token'] = axios1.Respuesta.token;

          axios
              .post(API_SYSCOM_URL+'GenerarPedido', this.order, config)
              .then(response => {
                if (response.data.Errores === null) {
                  resolve(response.data);
                }else{
                  this.loading = false;
                  this.order = JSON.parse(JSON.stringify(this.orderCopy));
                  this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:response.data.Errores.descripcion, life: 5000});
                }
                //resolve(response.data);
              })
              .catch(() => {
                this.loading = false;
                console.log(this.orderCopy,this.order);
                this.order = JSON.parse(JSON.stringify(this.orderCopy));
                this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'Error de solicitud', life: 5000});
              });
        });

        axios3 = await new Promise((resolve)=>{
          axios2.Respuesta['solicitud'] = this.paramId;
          this.postApiLocal('respuestasyscom', axios2.Respuesta)
              .then(response => {
                resolve(response.data);
              })
              .catch(() => {
                this.loading = false;
                this.order = JSON.parse(JSON.stringify(this.orderCopy));
                this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              });
        });

        Promise.all([axios1, axios2, axios3]).then(() => {
          this.loading = false;
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Solicitud enviada correctamente', life: 5000})
          this.$router.push({ name: "generatedorders" });
        });

      },
      async setOrder(){ //Almacenar pedido en nmvPostal.
        this.loading = true;
        var axios1, axios2, axios3;
        // Mitigar errores de selects y hacer copias de algunos datos
        this.saveData();

        //Fechas en formato datetime api local
        this.order.Encabezado.FecDespacho = this.fechas.FecDespacho;
        this.order.Encabezado.FecEntrega = this.fechas.FecEntrega
        this.order.Encabezado.FecVencePol = this.fechas.FecVencePol;
        this.order.Encabezado.Fecha = this.fechas.Fecha;
        //Guardar encabezado en nmvPostal
        axios1 = await new Promise((resolve)=>{
          this.order.Encabezado['solicitud'] = this.solicitudServicio;
          this.order.Encabezado['tipo_servicio'] = this.tipoServicio;
          this.postApiLocal('encabezado', this.order.Encabezado)
              .then(response => {
                resolve(response.data.data);
              })
              .catch(() => {
                this.loading = false;
                this.order = JSON.parse(JSON.stringify(this.orderCopy));
                this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              });
        });
        //Guardar detalles en nmvPostal
        console.log(this.order.Detalle);
        axios2 = await new Promise((resolve)=>{
          for (const i in this.order.Detalle) {
            this.order.Detalle[i].solicitud = axios1.id;
            this.order.Detalle[i].TarifaTabla = document.getElementById("TarifaTabla").value;
            this.order.Detalle[i].TarifaPago = document.getElementById("TarifaPago").value;
            this.order.Detalle[i].TarifSeguro = document.getElementById("TarifSeguro").value;
            this.order.Detalle[i].TarifaCliente = document.getElementById("TarifaCliente").value;
            this.postApiLocal('detalle', this.order.Detalle[i])
                .then(response => {
                  resolve(response.data);
                })
                .catch(() => {
                  this.loading = false;
                  this.order = JSON.parse(JSON.stringify(this.orderCopy));
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Error de solicitud',
                    detail: 'No se ha podido procesar la solicitud',
                    life: 5000
                  });
                });
          }
        });
        //Guardar conceptos en nmvPostal
        axios3 = await new Promise((resolve)=>{
          for (const i in this.order.Concepto) {
            this.order.Concepto[i].solicitud = axios1.id;
            this.postApiLocal('conceptos', this.order.Concepto[i])
                .then(response => {
                  resolve(response.data);
                })
                .catch(() => {
                  this.loading = false;
                  this.order = JSON.parse(JSON.stringify(this.orderCopy));
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Error de solicitud',
                    detail: 'No se ha podido procesar la solicitud',
                    life: 5000
                  });
                });
          }
          if(this.order.Concepto.length==0){
            resolve(null);
          }
        });

        Promise.all([axios1, axios2, axios3]).then(() => {
          this.loading = false;
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Solicitud enviada correctamente', life: 5000});

          if (this.method != 'put') {
            this.$router.push({name: "generatedorders"});
          } else {
            this.loading = false;
            this.order = JSON.parse(JSON.stringify(this.orderCopy));
          }
          /*let countServices = ((this.services.length-1) < 0) ? 0 : (this.services.length-1);
          if(this.servicePoint == countServices){
            this.$router.push({ name: "generatedorders" });
          }else{
            this.servicePoint++;
            this.order = JSON.parse(JSON.stringify(this.orderCopy));
            this.order.Detalle = [];
            this.setPost(this.services[this.servicePoint]);
          }*/
        });
      },
      postApiLocal(slug, data){
        const config = {
          headers: {
            "Authorization": "Bearer " + this.$store.state.auth.token,
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        };

        if (slug != 'respuestasyscom' && this.method == 'put') {

          return axios
              .put(API_LOCAL_URL+slug+'/'+data.id, data, config);

        } else {

          return axios
              .post(API_LOCAL_URL+slug, data, config);

        }

      },
      saveOrder(localOrder){
		    this.order = localOrder;
		    this.setOrder();
      },
      saveSyscom(localOrder){
        this.order = localOrder;
        this.setOrderSyscom();
      },
      setDatalle(){
        this.order.Detalle = [];
        this.setPost(this.services[this.valueDetalle.value]);
      }
    }
  }
</script>
